/* Works on Chrome, Edge, and Safari */
[data-theme=apricot] *::-webkit-scrollbar {
    width: 6px;
}
[data-theme=apricot] *::-webkit-scrollbar-track {
    background: var(--scroll-bg);
}
[data-theme=apricot] *::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 20px;
}
[data-theme=apricot] * {
    scrollbar-width: thin;
    scrollbar-color: var(--scroll-color) var(--scroll-bg);
}
[data-theme=apricot] .awesomplete .input-with-feedback {
    border: 1px solid var(--border-color);
}
[data-theme=apricot] .form-control {
    border: 1px solid var(--border-color);
}
[data-theme=apricot] .search-bar .awesomplete input {
    background-color: var(--bg-color)
}
[data-theme=apricot] .modal-backdrop{
    background-color: #36414c !important;
}
[data-theme=apricot] .widget .widget-head .widget-title {
    font-size: var(--text-base);
}
[data-theme=apricot] .comment-box .comment-input-header, .form-dashboard-section .section-head, .form-section .section-head, .head-title {
    font-size: var(--text-base);
    font-weight: 600;
}
[data-theme=apricot] .comment-box .comment-input-container .ql-editor {
    border: 1px solid var(--border-color);
}
[data-theme=apricot] .duration-picker .duration-input {
    background-color: var(--bg-color);
    border: 1px solid var(--border-color);
}
[data-theme=apricot] .control-label {
    font-weight: 500;
}
[data-theme="dark"] {
    --bg-color: var(--gray-900);
    --disabled-control-bg: var(--gray-900);
    --border-color: #1c2126;
    --margin-lg : 15px;
    --text-bold: 600;
    --scroll-bg: #161a1f;
    --scroll-color: #1c2126;
}

[data-theme="light"] {
    --bg-color: #4c5ead;
    --text-bold: 600;
    --control-bg : #fff;
    --control-bg-on-gray: #d4dcea;
    --disabled-control-bg: #f2f5fa;
    --heading-color: #333;
    --border-color:#e3e8f1;
    --avatar-frame-bg: #dde2ea;
    --margin-lg: 15px;
    --sidebar-select-color: #d4dcea;
    --awesomplete-hover-bg: #d4dcea;
    --scroll-bg: #eef1f5;
    --scroll-color: #d9dfe6;
}
.container page-body{
margin-left: 0px;
}
.page-head{
    background: #f0f4f6!important;
    background: #f0f4f6 !important;
    

}
/*****  OVERRIDDEN CSS  *****/

/* Heading Text Color */
/*
.page-head{
    background: #7C7BAD !important;
    background: #7C7BAD !important;
}
.page-container{
    background: url(/web_responsive/static/src/css/../img/home-menu-bg-overlay.svg), linear-gradient(to bottom, #7C7BAD, #c3c2cc); !important;
    background-color: url(/web_responsive/static/src/css/../img/home-menu-bg-overlay.svg), linear-gradient(to bottom, #7C7BAD, #c3c2cc); !important;
}
h3.ellipsis.title-text,
.widget-group .widget-group-head .widget-group-title,
.layout-side-section .sidebar-label{
    color: white !important;
}

*/
/* Form Input Focus *//*

input:focus{
    border: 1px solid #7C7BAD !important;
}
.form-control:focus{
    box-shadow: 0 0 0 2px rgba(94, 108, 162, 0.25) !important;
}

*/
/* Div Padding *//*

.widget.widget-shadow.shortcut-widget-box{
    padding: 5px 10px;
}

*/
/* List Design *//*

.indicator-pill, .indicator-pill-right{
    padding: 7px 8px;
    height: unset;
}

*/
/* Collapse Div Padding *//*

.section-head.collapsed{
    padding: 2px 4px;
}

*/
/* Dropdown List Hover */

[data-theme=apricot] .awesomplete > ul > li:hover, .awesomplete > ul > li[aria-selected="true"]{
    background-color: #ffa00a;
    color: #fff;
}

[data-theme=apricot] [data-label="Create Workspace"]{
    background-color: #ffa00a;
    color: #fff;
}

/* Global CSS */

/* Colours */
[data-theme=apricot] {
    --background-color: #4d60ae;
    --primary-color: #ffa00a;
    --secondary-color: #7574ff;
    --white-color: #ffffff;
    --black-color: #000000;
    --gray-color: #888;
    --page-head-height: 50px;
    --navbar-height: 50px;
}
[data-theme=apricot] .content.page-container {
    background-color: #f0f4f6!important;  /* Replace with your desired background color */
}
.col-lg-2.layout-side-section {
    overflow-y: scroll; /* Keeps content scrollable */
    scrollbar-width: none;  /* For Firefox */
    border-radius: 9px;
    padding: 10px;
    margin-bottom: 10px;
    /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
.col.layout-main-section-wrapper {
    overflow-y: scroll; /* Keeps content scrollable */
    scrollbar-width: none;  /* For Firefox */
    border-radius: 4px;
    
}
.col-lg-2.layout-side-section::-webkit-scrollbar {
    display: none;  /* Hides scrollbar for WebKit-based browsers (Chrome, Safari) */
}
[data-page-route=Workspaces] .widget.number-widget-box {
    border: 1px solid var(--border-color, #ccc); /* Fallback to #ccc if --border-color is undefined */
    background-color: lightskyblue !important; /* Force background color if needed */
}
.list-row-head span{
    color: #faf9f9 !important;
}
.list-row-head{
    background-color: #4d60ae !important;
}
[data-theme=apricot] .widget.number-widget-box .widget-title {
    font-size: 18px;              /* Title font size */
    font-weight: bold;            /* Bold text */
    color: #090909;                  /* White text color */
    text-transform: uppercase;    /* Uppercase transformation */
    padding-right: 10px;                /* Padding around the title */
       /* Apricot background color */
}

[data-theme=apricot] .widget.number-widget-box .widget-body .widget-content .number {
    font-size: var(--text-3xl);             /* Responsive font size */
    font-weight: var(--weight-semibold);    /* Semi-bold font weight */
    letter-spacing: 0.01em;                /* Letter spacing adjustment */
    line-height: var(--text-line-height-3xl); /* Line height definition */
    color: var(--text-color);  
    padding-right: 10px;             /* Theme text color */
}


[data-theme=apricot] .btn-primary:active,
[data-theme=apricot] .btn-secondary:focus,
[data-theme=apricot] .btn-secondary:active,
[data-theme=apricot] .btn-secondary:focus{
    box-shadow: none !important;
}
/* [data-theme=apricot] .btn-primary{
    /* color: #fff !important;
    background-color: var(--primary-color) !important;
    border-radius: 0 !important; */  

[data-theme=apricot] .btn-primary {
    background: #FF4742;
    border: 1px solid #FF4742;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: nunito, roboto, proxima-nova, "proxima nova", sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    -moz-user-select: none;
         user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
  }
  
  [data-theme=apricot] .btn-primary:hover,
  [data-theme=apricot] .btn-primary:active {
    background-color: initial;
    background-position: 0% 0;
    color: #4d60ae;
  }
  
  [data-theme=apricot] .btn-primary:active {
    opacity: 0.5;
  }
  
[data-theme=apricot] .btn-primary:hover{
    background-color: #4d60ae !important;
}
.btn-secondary{
    background-color: #606060 !important;
    color: var(--white-color) !important;
    border-radius: 0 !important;
    transition: background-color ease-in-out .15s;
}
.btn-secondary:hover{
    background-color: #4d60ae !important;
}
[data-theme=apricot] .page-head,
[data-theme=apricot] .page-head .page-head-content{
    height: var(--page-head-height);
}
[data-theme=apricot] .page-head,
[data-theme=apricot] .page-head .page-head-content{
    top: var(--page-head-height);
}
.like-icon {
    display: none;
}

.comment-count{
    display: none !important;
}
.level-item .mx-2{
    display: none !important;
}
.level-item .like-action{
    display: none !important;
}
.level-item svg{
    display: none !important;
}
/* Top Header */
[data-theme=apricot] .navbar{
    background: var(--background-color) !important;
    height: var(--navbar-height);
}
[data-theme=apricot] .navbar-expand{
    background-color: var(--background-color);
}
[data-theme=apricot] #navbar-breadcrumbs > li > a::before{
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    margin-right: .3em;
    display: inline-block;

    font-size: 24px;
    transition: 0.2s;
    position: relative;
    top: 3px;
    content: "\f105";
    margin-right: 10px;
    color: var(--white-color);
}
[data-theme=apricot] #navbar-breadcrumbs a{
    color: var(--white-color);
    text-decoration: none;
}
[data-theme=apricot] header .form-control{
    color: var(--white-color);
}
[data-theme=apricot] header .form-control:focus{
    border: 0;
}
[data-theme=apricot] .search-bar .awesomplete input{
    height: 32px;
    color: var(--heading-color);
}
/*#navbar-search{
    width: 300px;
    background-color: rgb(65, 78, 90);
    border-radius: 0px;
    color: #f9f9f9;
    border: none;
}*/
[data-theme=apricot] header ul#navbar-breadcrumbs{
    margin-top: -8px;
}
[data-theme=apricot] header .form-control:focus{
    background-color: var(--white-color) !important;
}



/* Sidebar */
[data-theme=apricot] .layout-side-section .sidebar-label{
    color: var(--primary-color);
}
[data-theme=apricot] .sidebar-label svg,
[data-theme=apricot] .layout-side-section .sidebar-label .icon{
    stroke: var(--primary-color);
}
[data-theme=apricot] .list-sidebar-button{
    background-color:#2196f31f!important;
    box-shadow: none !important;
}
[data-theme=apricot] .list-sidebar-button:active,
[data-theme=apricot] .list-sidebar-button:focus{
    text-decoration: none !important;
    box-shadow: none !important;
}


/* Input Fields */
[data-theme=apricot] input{
    border-radius: 0 !important;
}
[data-theme=apricot] .form-control{
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    background-color: #f2f2f2 !important;
}
[data-theme=apricot] .form-control:focus{
    outline: 0;
    border: 1px solid var(--primary-color);
    box-shadow: none;
    background: rgba(255, 160, 10, 0.14) !important;
}
[data-theme=apricot] .navbar .navbar-nav a.nav-link{
    color: var(--white-color);
}
[data-theme=apricot] span.notifications-seen use,
[data-theme=apricot] symbol#icon-small-down{
    fill: var(--white-color);
}


/* Table */
[data-theme=apricot] .form-grid{
    border-radius: 0;
    border: 1px solid #bbbbbb;
}
[data-theme=apricot] .form-grid .grid-heading-row{
    background-color: #f4f4f4;
}
[data-theme=apricot] .grid-heading-row{
    border-bottom: 1px solid var(--primary-color);
}
[data-theme=apricot] .grid-row > .row .col{
    border-right: 1px solid #d1d8dd;
}
[data-theme=apricot] .form-grid .grid-row:hover{
    background: rgba(255, 152, 0, 0.15);
}

/* Heading Color */
[data-theme=apricot] .head-title, .form-section .section-head, .form-dashboard-section .section-head, .comment-box .comment-input-header{
    color: var(--primary-color);
}

/* Lists */
[data-theme=apricot] .list-subject a{
    text-decoration: none;
    transition: 0.3s;
}
[data-theme=apricot] .list-subject a:hover{
    color: var(--secondary-color);
}

/* Navigation Bar .es-icon */
.container .navbar-nav .dropdown-help span {
    color: #fff !important;  /* Set the text color of list items */
}
.container .navbar-nav .dropdown-notifications .notifications-seen .es-icon {
      /* Set the text color of list items */
    fill: #f8f5f5!important;
    stroke: #efe5e5e3!important;
}

[data-theme=apricot] .navbar .navbar-nav a.nav-link {
    color: var(--white-color) !important;  /* Ensure nav-link text color is applied */
}

[data-theme=apricot] .navbar-nav>li>a {
    color: #fff !important;  /* Set text color of links inside li */
}

[data-theme=apricot] .navbar-nav>li>a:hover {
    color: #ddd !important;  /* Hover color for links */
}

[data-theme=apricot] .navbar-brand:hover {
    color: #ddd !important;  /* Hover color for brand */
}
/* Set color for icons in the navbar */
[data-theme=apricot] .navbar-nav>li a i {
    color: #fff !important;  /* Set the color of the icons */
}

/* Set icon color on hover */
[data-theme=apricot] .navbar-nav>li a:hover i {
    color: #ddd !important;  /* Hover color for icons */
}




/* Report Table */
[data-theme=apricot] .datatable .dt-header .dt-cell--header .dt-cell__content{
    background-color: #f4f4f4;
}


/* Buttons CSS
.filter-button,
.sort-selector-button,
.btn-order{
    background-color: #ea7272 !important;
    color: var(--white-color) !important;
    transition: 0.3s;
    border-radius: 0 !important;
}
.filter-button:hover,
.sort-selector-button:hover,
.btn-order:hover{
    background-color: #c83b3b !important;
}
.filter-button span.filter-icon{
    color: var(--white-color);
}
.filter-button span.filter-icon svg.icon{
    stroke: var(--white-color);
} */
